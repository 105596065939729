import { createRouter, createWebHistory } from 'vue-router';
import MapComponent from '../components/MapComponent.vue';
import Overview from '../views/Overview.vue';
import Technology from '../views/Technology.vue';
import Equipment from '../views/Equipment.vue';
import Contact from '../views/Contact.vue';
import FAQs from '../views/FAQs.vue';
import Partners from '../views/Partners.vue';

const routes = [
  { path: '/', component: MapComponent },
  { path: '/overview', component: Overview },
  { path: '/technology', component: Technology },
  { path: '/equipment', component: Equipment },
  { path: '/contact', component: Contact },
  { path: '/faqs', component: FAQs },
  { path: '/partners', component: Partners }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
