<template>
    <div>
      <!-- Navigation Bar with Back Button -->
      <nav class="navbar navbar-light bg-light head-navbar">
        <div class="container-fluid">
          <button @click="goBack" class="btn btn-link" style="color: inherit; text-decoration: none;">
            <i class="mdi mdi-chevron-left"></i>
          </button>
          <span class="navbar-brand mb-0 h1">{{ title }}</span>
          <button @click="goHome" class="btn btn-link" style="color: inherit; text-decoration: none;">
            <i class="mdi mdi-close"></i>
          </button>
        </div>
      </nav>
    </div>
  </template>

<script>
export default {
  name: 'OverviewPage',
  props: {
    title: String
  },
  data() {
      return {
        pageContent: '',
        loading: true
      };
    },
  methods: {
    goBack() {
      this.$router.push('/');
    },
    goHome() {
      this.$router.push('/');
    },
  }
}
</script>