<template>
  <v-app>
    <v-app-bar>
      <v-app-bar-title class="app-bar">
        <a href="https://gwt.vn" class="logo-link" target="_blank">
          <img src="@/assets/logo_crop.png" alt="Logo" class="map-logo" />
          <div class="logo-text">
            <div>Mineral Map</div>
            <div>Bản đồ khoáng</div>
          </div>
        </a>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon 
        class="hidden-md-and-up"
        @click="goTechnology()"
      >
        <img class="info-icon" src="@/assets/info.svg" alt="Info icon" />
      </v-btn>
      <v-btn
        class="hidden-sm-and-down mineral-map-info-button"
        @click="goTechnology()"
      >
        <img class="info-icon" src="@/assets/info.svg" alt="Info icon" /> Về MineralMap   
      </v-btn>

    </v-app-bar>
    <div class="global-header">
      <a href="https://gwt.vn" class="logo-link" target="_blank">
        <img src="@/assets/logo_crop.png" alt="Logo" class="map-logo">
        <div class="logo-text">
          <div>Mineral Map</div>
          <div>Bản đồ khoáng</div>
        </div>
      </a>
      <div class="info-wrapper">
        <div class="info-icon-wrapper">
          <img class="info-icon" src="@/assets/info.svg" alt="Info icon" />
        </div>
      </div>
    </div>
    <GoogleMap
      api-key="AIzaSyDkPz3oprKPicEqiw0cuurv8TKwiU3mbTU"
      :center="center"
      :zoom="15"
      :styles="mapStyles"
      :map-type-control="mapOptions.mapTypeControl"
      :street-view-control="mapOptions.streetViewControl"
      :fullscreen-control="mapOptions.fullscreenControl"
      style="width: 100%; height: 100%"
    >
      <CustomMarker
        v-for="location in locations"
        :key="location.id"
        :options="location.markerOptions"
        @click="handleMarkerClick(location.id)"
      >
        <div style="text-align: center">
          <img
            :src="customGoogleMarker"
            width="50"
            height="50"
            style="margin-top: 8px"
          />
          <span class="number-inside-water">
            {{ location.tds }}
          </span>
        </div>
      </CustomMarker>
    </GoogleMap>
    <button class="locate-button" @click="locateUser">
        <i class="mdi mdi-crosshairs-gps"></i>
    </button>
    <LocationDetailModal
      :details="selectedDetails"
      :visible="isDialogVisible"
      @update:visible="handleVisibilityChange"
    />
  </v-app>
</template>
  
<script>
import { GoogleMap, CustomMarker } from "vue3-google-map";
import LocationDetailModal from "./maps/LocationDetailModal.vue";
import axios from "axios";
import customGoogleMarker from "@/assets/google-map-marker.svg";

export default {
  components: {
    GoogleMap,
    CustomMarker,
    LocationDetailModal,
  },
  data() {
    return {
      center: { lat: 21.0285, lng: 105.8542 },
      locations: [],
      isDialogVisible: false,
      selectedDetails: {},
      googleMaps: null,
      customGoogleMarker,
      apiUrl: process.env.VUE_APP_API_URL,
      mapOptions: {
        mapTypeControl: false, // This disables the map type toggle buttons
        // You can also disable other controls here if needed
        streetViewControl: false, // Example: To hide the Street View control
        rotateControl: false, // Example: To hide the rotate control
        scaleControl: false, // Example: To show the scale control
        fullscreenControl: false, // Example: To show the scale control
      },
      mapStyles: [
        { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
        { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [{ color: "#bdbdbd" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#dadada" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#616161" }],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#c9c9c9" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9e9e9e" }],
        },
      ],
    };
  },
  async mounted() {
    // this.mockClick();
    await this.getAllPlaces();
    document.title = 'Mineral Map';
  },
  methods: {
    // onMapLoad() {
    //   this.googleMaps = window.google.maps; // Ensure google maps object is available
    //   const input = document.getElementById('search-box');
    //   const searchBox = new this.googleMaps.places.SearchBox(input);

    //   // Bias results towards the current map viewport (optional)
    //   searchBox.bindTo('bounds', this.$refs.map); // Assuming your map ref is "$refs.map"

    //   // Handle place selection
    //   searchBox.addListener('places_changed', () => {
    //     const places = searchBox.getPlaces();
    //     if (places.length > 0) {
    //       const place = places[0];
    //       // Update map center or handle place selection logic
    //       this.center = {
    //         lat: place.geometry.location.lat(),
    //         lng: place.geometry.location.lng(),
    //       };
    //     }
    //   });
    // },
    goTechnology() {
      this.$router.push('/technology');
    },
    onMapLoad() {
      this.googleMaps = window.google.maps;
      const input = document.getElementById("search-box");
      const autocomplete = new this.googleMaps.places.Autocomplete(input);
      autocomplete.setFields(["address_components", "geometry", "name"]);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.error("No details available for input: '" + place.name + "'");
          return;
        }
        this.center = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.zoom = 15;
      });
    },
    convertPlaceObjectToMarkerOptions(place) {
      return {
        position: { lat: place.latitude, lng: place.longitude },
        label: place.id.toString(),
        title: place.id.toString(),
      };
    },
    handleMarkerClick(id) {
      console.log(`CLICKED ON MARKER ${id}`);
      axios
        .get(`${this.apiUrl}/api/places/${id}/`)
        .then((response) => {
          this.selectedDetails = response.data;
          this.isDialogVisible = true;
        })
        .catch((error) => {
          console.error("Error fetching location details:", error);
          this.selectedDetails = {}; // Reset or handle error state
          this.isDialogVisible = false; // Optionally open the modal with error info
        });
    },
    mockClick() {
      const data = {
        id: 2,
        name: "Trung Hoa",
        description: "x",
        address: "17T9 Nguyễn Thị Thập, p Trung Hoà",
        ward: "Trung Hoa",
        district: "Cau Giay",
        city: "Ha Noi",
        latitude: 21.00759,
        longitude: 105.80379,
        raw_google_map_url:
          "https://www.google.com/maps/place/17T9/@21.0074532,105.8012295,17z/data=!4m7!3m6!1s0x3135aca195be19cb:0x751d4d3fdc8f776e!4b1!8m2!3d21.0074482!4d105.8038044!16s%2Fg%2F12hm4fr5x?entry=ttu",
        watermineralinformation_set: [
          {
            id: 2,
            created_at: "2024-05-20T22:16:52.658365+07:00",
            modified_at: "2024-05-20T22:16:52.658424+07:00",
            canxi_concentration: 181.0,
            magie_concentration: 16.8,
            zinc_concentration: 0.559,
            kali_concentration: 55.4,
            natri_concentration: 124.0,
            selen_concentration: 0.0,
            stronium_concentration: 4.7,
            h2sio4_concentration: 54.0,
            created_by: null,
            modified_by: null,
            place: 2,
          },
        ],
      };
      const waterMineralData = data.watermineralinformation_set[0];
      this.selectedDetails = {
        id: data.id,
        address: data.address,
        ward: data.ward,
        district: data.district,
        city: data.city,
        minerals: [
          {
            name: "Canxi",
            concentration: waterMineralData.canxi_concentration,
          },
          {
            name: "Magie",
            concentration: waterMineralData.magie_concentration,
          },
          { name: "Zinc", concentration: waterMineralData.zinc_concentration },
          { name: "Kali", concentration: waterMineralData.kali_concentration },
          {
            name: "Natri",
            concentration: waterMineralData.natri_concentration,
          },
          {
            name: "Selen",
            concentration: waterMineralData.selen_concentration,
          },
          {
            name: "Stronium",
            concentration: waterMineralData.stronium_concentration,
          },
          {
            name: "H2SiO4",
            concentration: waterMineralData.h2sio4_concentration,
          },
        ],
      };
      this.isDialogVisible = true;
    },
    handleVisibilityChange(value) {
      this.isDialogVisible = value;
    },
    async getAllPlaces() {
      axios
        .get(`${this.apiUrl}/api/places/`)
        .then((response) => {
          console.log(response.data);
          const dataset = response.data;
          // loop through data set and transform
          dataset.forEach((data) => {
            this.locations.push({
              id: data.id,
              tds: data.tds,
              markerOptions: this.convertPlaceObjectToMarkerOptions(data),
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching locations:", error);
          this.locations = []; // Reset or handle error state
        });
    },
    async getWaterMineralMasterData() {
      axios
        .get(`${this.apiUrl}/api/water-mineral-master-data/`)
        .then((response) => {
          const dataset = response.data;
          dataset.forEach((data) => {
            this.waterMineralMasterData[data.name] = {
              id: data.id,
              name: data.name,
              description: data.description,
              threshold_suggestion: data.threshold_suggestion,
            };
          });
        })
        .catch((error) => {
          console.error("Error fetching water mineral master data:", error);
        });
    },
    locateUser() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.zoom = 15; // Adjust zoom level as needed
          },
          () => {
            alert("Unable to retrieve your location");
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },
  },
};
</script>
  
<style>
/* Style your component */
.number-inside-water {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: white;
}
#search-box {
  width: 200px; /* Adjust width as needed */
  height: 40px; /* Adjust height to match design */
  padding: 10px;
  border: none; /* Remove default border */
  border-radius: 20px; /* Rounded corners */
  font-size: 16px; /* Suitable font size for visibility */
  background-color: #fff; /* White background */
  color: #333; /* Dark text for readability */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for 3D effect */
  outline: none; /* Remove outline on focus */
  position: absolute; /* Absolute positioning */
  top: 20px; /* Distance from the top of the map */
  left: 140px; /* Distance from the left side of the map */
  z-index: 5; /* Ensure it's above other map layers */
}

#search-box:focus {
  border: 2px solid #4a90e2; /* Highlight color on focus */
}

#search-box::placeholder {
  /* Style for the placeholder text */
  color: #aaa; /* Lighter text color for placeholder */
  font-style: italic; /* Italicize placeholder */
}
.locate-button {
    position: absolute;
    bottom: 20px; /* Distance from the bottom of the container */
    left: 20px; /* Distance from the left side of the container */
    z-index: 5; /* Above the map */
    background-color: #fff; /* White background */
    border: none; /* No border */
    border-radius: 50%; /* Circular button */
    padding: 10px; /* Padding around the icon */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Shadow for 3D effect */
    cursor: pointer; /* Cursor pointer on hover */
    outline: none; /* Remove outline to keep style clean */
    display: flex; /* Ensures the icon centers in the button */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 40px; /* Fixed width */
    height: 40px; /* Fixed height */
}

.locate-button i {
    color: #333; /* Icon color */
    font-size: 16px; /* Icon size */
}
.global-header {
  display: flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Align items to the start */
  padding: 10px; /* Add some padding around */
  background-color: #fff; /* Optional: background color */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds shadow for depth */
  position: absolute; /* Positioning relative to its nearest positioned ancestor */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  z-index: 100; /* Make sure it's on top of other content */
}

.map-logo {
  height: 40px; /* Adjust based on your preference */
  margin-right: 15px; /* Space between logo and text */
}

.logo-text div {
  line-height: 1.2; /* Adjust line spacing */
  color: #2AAAE0; /* First line color */
  font-family: 'Inter', sans-serif; /* Font family */
  font-weight: 600; /* Font weight for the first line */
}

.logo-text div:last-child {
  font-size: 12px; /* Smaller font size for the second line */
  color: #757575; /* Second line color */
  font-weight: 500; /* Font weight for the second line */
}

.logo-link {
  display: flex; /* Align logo and text side by side */
  text-decoration: none; /* Remove underline from link */
}
.app-bar {
  font-size: 1rem;
}

.info-wrapper {
  display: flex;
  align-items: center;
}
.mineral-map-info-button {
  color: #4C99DB;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 107.143% */
}
</style>