<template>
    <div>
      <MobileHeader title="Contact" />
      
      <div v-if="loading">
        Loading...
      </div>
      <div v-else>
        <div id="contact" class="page">
          <div class="content">
            <div class="company-info">
              <img class="logo-contact" src="../assets/logo_contact.png" />
              <h2>Công ty TNHH Công nghệ Nước General (GWT)</h2>

              <div class="contact-info">
                <p class="title">Dia chi</p>
                <p class="content">Tầng 6, Tòa nhà Capital Palace, Liễu Giai, Ba Đình, Hà Nội</p>
              </div>

              <div class="contact-info">
                <p class="title">Hotline</p>
                <p class="content">1900 3363</p>
              </div>

              <div class="contact-info">
                <p class="title">Website</p>
                <p class="content"><a href="http://gwt.vn">gwt.vn</a></p>
              </div>

              <div class="contact-info">
                <p class="title">Email</p>
                <p class="content"><a href="mailto:contact@gwt.vn">contact@gwt.vn</a></p>
              </div>
            </div>
            <div class="social-links">
              <a href="https://zalo.me"><img src="{% static 'icons/zalo-icon.svg' %}" alt="Zalo"></a>
              <a href="https://facebook.com"><img src="{% static 'icons/facebook-icon.svg' %}" alt="Facebook"></a>
              <a href="https://youtube.com"><img src="{% static 'icons/youtube-icon.svg' %}" alt="YouTube"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import MobileHeader from '../components/MobileHeader.vue';
  
  export default {
    name: 'ContactPage',
    components: {
      MobileHeader
    },
    data() {
        return {
          pageContent: '',
          loading: true
        };
      },
      mounted() {
        this.fetchPageContent();
      },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      fetchPageContent() {
          axios.get('http://localhost:8000/api/flatpages/contact/')  // Adjust URL as needed for your Django setup
            .then(response => {
              this.pageContent = response.data.content;  // Adjust according to the response structure
              this.loading = false;
            })
            .catch(error => {
              console.error('Error fetching page content:', error);
              this.loading = false;
            });
        }
    }
  }
  </script>
  
<style scoped>
.navbar {
  box-shadow: 0 2px 4px rgba(0,0,0,.1); /* Optional: adds shadow for depth */
}
.page {
  background-color: #F1F1F1;
}

.back-button, .close-button {
    border: none;
    background: none;
}

.back-button img, .close-button img {
    width: 24px;
    height: 24px;
}

.content {
    padding: 1rem;
}

.company-info h2 {
    margin-top: 1rem;
}

.logo {
    width: 100px; /* Adjust size as needed */
    margin: auto;
}

.address, .hotline, .website, .email {
    margin-top: 0.5rem;
}

.social-links a {
    margin: 0 10px;
    display: inline-block;
}

.social-links img {
    width: 40px; /* Adjust size as needed */
}

.logo-contact {
    width: 100px;
    margin: auto;
}
  </style>
    