<template>
  <div>
    <MobileHeader title="OverviewWW" />
    <div v-if="loading">
      Loading...
    </div>
    <div v-else>
      <div class="page-content" v-html="pageContent"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MobileHeader from '../components/MobileHeader.vue';

export default {
  name: 'OverviewPage',
  components: {
    MobileHeader
  },
  data() {
      return {
        pageContent: '',
        loading: true
      };
    },
    mounted() {
      this.fetchPageContent();
    },
  methods: {
    goBack() {
      this.$router.push('/');
    },
    fetchPageContent() {
        axios.get('http://localhost:8000/api/flatpages/overview/')  // Adjust URL as needed for your Django setup
          .then(response => {
            this.pageContent = response.data.content;  // Adjust according to the response structure
            this.loading = false;
          })
          .catch(error => {
            console.error('Error fetching page content:', error);
            this.loading = false;
          });
      }
  }
}
</script>

<style scoped>

</style>
  