import { createApp } from 'vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import router from './router';
import "./global.css"

// Components
import App from './App.vue'

const vuetifyOpts = {
    theme: {
      themes: {
        light: {
          primary: '#3f51b5',  // Blue
          secondary: '#b0bec5', // Light blue-grey
          accent: '#8c9eff',    // Light blue accent
          error: '#b71c1c',     // Deep red
        },
        dark: {
          primary: '#2196f3',   // Light blue
          secondary: '#424242', // Dark grey
          accent: '#ff5252',    // Bright red accent
          error: '#ff4444',     // Bright red
        }
      }
    },
    components,
    directives,
  };
  

const vuetify = createVuetify(vuetifyOpts)

const app = createApp(App)
app.use(vuetify)
app.use(router)
app.mount('#app')