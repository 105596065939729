<template>
  <v-layout class="rounded rounded-md outter-layout">
    <v-app-bar>
      <v-app-bar-title class="app-bar">
        <a href="https://gwt.vn" class="logo-link" target="_blank">
          <img src="@/assets/logo_crop.png" alt="Logo" class="map-logo" />
          <div class="logo-text">
            <div>Mineral Map</div>
            <div>Bản đồ khoáng</div>
          </div>
        </a>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon 
        class="hidden-md-and-up"
        @click="goBackHome()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn
        class="hidden-sm-and-down"
        @click="goBackHome()"
      >
        <v-icon>mdi-close</v-icon> Quay lại bản đồ
      </v-btn>

    </v-app-bar>
    <v-main class="body-content">
      <v-container>
        <v-card
          class="mx-auto banner"
        >
          <v-img
            :src="pageContent.section_1_banner_url"
            cover
          ></v-img>
          <v-card-text class="banner-text">
            <span v-html="pageContent.section_1_content"></span>
          </v-card-text>
          <v-divider></v-divider>
          <v-expansion-panels>
            <v-expansion-panel v-for="(panel, index) in pageContent.page_technology_section_2" :key="index">
              <v-expansion-panel-title>
                <h6>{{ panel.title }}</h6>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <span v-html="panel.content"></span>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-container>
      <v-container>
        <v-card
          class="mx-auto hidden-md-and-up thomas-edison-banner"
        >
          <v-img
            :src="pageContent.section_3_banner_url"
            cover
          ></v-img>
          <v-card-text>
            <span v-html="pageContent.section_3_content"></span>
          </v-card-text>
        </v-card>
        <v-card
          class="mx-auto hidden-sm-and-down thomas-edison-banner"
        >
          <v-row>
            <v-col cols="6">
              <v-img
                :src="pageContent.section_3_banner_url"
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-card-text>
                <span v-html="pageContent.section_3_content"></span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <v-container class="d-flex flex-column align-start">
        <v-card
          class="mx-auto hidden-md-and-up footer"
        >
          <v-img
            class="contact-logo"
            height="60px"
            :src="pageContent.section_4_banner_url"
          ></v-img>
          <v-card-text>
            <span v-html="pageContent.section_4_sub_title"></span>
          </v-card-text>
          <v-card-text>
            <span v-html="pageContent.section_4_content"></span>
          </v-card-text>
        </v-card>
      </v-container>
      <v-container class="d-flex flex-column align-start">
        <v-card
          class="mx-auto hidden-sm-and-down footer"
        >
          <v-row>
            <v-col cols="6">
              <v-img
                class="contact-logo"
                height="60px"
                :src="pageContent.section_4_banner_url"
              ></v-img>
              <v-card-text>
                <span v-html="pageContent.section_4_sub_title"></span>
              </v-card-text>
            </v-col>
            <v-col cols="6">
              <v-card-text>
                <span v-html="pageContent.section_4_content"></span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <v-divider></v-divider>
    </v-main>
  </v-layout>
</template>
  
  <script>
import axios from 'axios';

export default {
  name: "TechnologyPage",
  components: {},
  data() {
    return {
      pageContent: "",
      loading: true,
    };
  },
  mounted() {
    this.fetchPageContent();
  },
  methods: {
    goBackHome() {
      // Go to home
      this.$router.push("/");
    },
    fetchPageContent() {
          axios.get('https://mineralmap.vn/api/page-technology/')  // Adjust URL as needed for your Django setup
            .then(response => {
              this.pageContent = response.data;  // Adjust according to the response structure
              this.loading = false;
            })
            .catch(error => {
              console.error('Error fetching page content:', error);
              this.loading = false;
            });
        }
  },
};
</script>
  
<style scoped>
.outter-layout {
  overflow: scroll !important;
}
.body-content {
  background: #FAFAFA;
  width: 100%;
  max-width: 792px;
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .body-content {
    width: 75%; /* Slightly narrower on tablets */
    margin: auto; /* Center align if less than full width */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .body-content {
    width: 60%; /* Narrower on desktops */
    margin: auto; /* Center align if less than full width */
  }
}
.app-bar {
  font-size: 1rem;
}
.banner-text {
  color: #2C537D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 155.556% */
}
.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.contact-title {
  color: #262626;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
}
.contact-content {
  color: rgba(38, 38, 38, 0.85);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
}
.contact-logo {
  width: 100%;
  margin-top: 1rem;
}

</style>
    