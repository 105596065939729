<template>
  <v-dialog v-model="isDialogVisible" width="343" persistent>
    <v-card class="modal-card">
      <div class="header">
        <div class="close" @click="closeModal()"></div>
        <div class="name">{{ details.name }}</div>
        <div class="address">
          {{ details.address }}
        </div>
        <div class="statistics">
          <div class="statistic" data-id="tds">
            <div class="label">TDS</div>
            <div class="value">{{ details.tds }}</div>
            <div class="unit">mg/L</div>
          </div>
          <div class="statistic" data-id="ph">
            <div class="label">Độ pH</div>
            <div class="value">{{ details.ph }}</div>
          </div>
        </div>
      </div>
      <div class="body">
        <div class="waterdrop">
          <img src="../../assets/modal-mineral.body.waterdrop.image.svg" />
        </div>
        <div class="information">
          {{ details.water_info_summary }}
        </div>
        <div class="mineral-slider"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        >
          <div
              v-for="(mineral, index) in details.minerals"
              :key="mineral.name"
              :class="{
                active: mineral.name.toLowerCase() === activeMineral.name.toLowerCase(),
                item: true,
              }"
              :data-slug="`${mineral.name.toLowerCase()}`"
              :style="getSliderItemStyle(index)"
              @click="setActiveMineral(mineral)"
          >
            {{ mineral.name }}
          </div>
        </div>
        <div class="mineral-info">
          <div class="mineral-benefits">
            {{ activeMineral.description }}
          </div>
          <div class="mineral-concentration">
            <span>{{ activeMineral.concentration }} mg/l</span>
          </div>
          <div class="mineral-suggestion">
            <img src="../../assets/icon.hand-heart.svg" /> Ngưỡng khuyến cáo<br/>
            <b>{{ activeMineral.threshold_suggestion }}</b>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-item">
          <img src="../../assets/logo.general-water-technologies.png" />
        </div>
        <div class="footer-item">
          <img src="../../assets/logo.general-electric.svg" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    details: {
      type: Object,
      default: () => ({
        address: '123 Sample Street',
        minerals: [
            {name: 'Canxi', concentration: '120'},
            {name: 'Magie', concentration: '30'},
            {name: 'Kẽm', concentration: '15'},
            {name: 'Kali', concentration: '5'},
            {name: 'Natri', concentration: '25'},
            {name: 'Selen', concentration: '0.5'},
            {name: 'Stronium', concentration: '0.2'},
            {name: 'H2SiO4', concentration: '1'}
        ]
      })
    },
    visible: {
      type: Boolean,
      default: false
    },
    waterMineralMasterData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isDialogVisible: this.visible,
      activeMineral: {
        name: '',
        concentration: 0,
      },
      indexMineralMapping: {
        0: 'canxi',
        1: 'magie',
        2: 'zinc',
        3: 'kali',
        4: 'natri',
        5: 'selen',
        6: 'stronium',
        7: 'h2sio4'
      }
    };
  },
  async mounted() {
  },
  computed: {
    formattedMinerals() {
      return Object.entries(this.details.minerals).map(([key, value]) => ({
        name: key,
        concentration: value
      }));
    },
  },
  watch: {
    visible(newVal) {
      this.isDialogVisible = newVal;
    },
    details(newVal, oldVal) {
      // Check if details.minerals has changed (length or content)
      if (newVal.minerals && (!oldVal || !oldVal.minerals || newVal.minerals.length !== oldVal.minerals.length || JSON.stringify(newVal.minerals) !== JSON.stringify(oldVal.minerals))) {
        // If details.minerals changed, set activeMineral to the first mineral
        this.activeMineral = newVal.minerals[3];
      }
    },
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.touchEndX = 0; // Reset end position
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      if (this.touchEndX !== 0) { // Ensure that a swipe occurred
        const direction = this.touchStartX - this.touchEndX > 0 ? 'left' : 'right';
        this.updateActiveMineral(direction);
      }
    },
    updateActiveMineral(direction) {
      const currentIndex = this.details.minerals.findIndex(mineral => 
        mineral.name.toLowerCase() === this.activeMineral.name.toLowerCase());
      let nextIndex;
      if (direction === 'right') {
        nextIndex = currentIndex - 1;
        if (nextIndex < 0) {
          nextIndex = this.details.minerals.length - 1; // Loop back to the last item
        }
      } else { // direction is left
        nextIndex = currentIndex + 1;
        if (nextIndex >= this.details.minerals.length) {
          nextIndex = 0; // Loop back to the first item
        }
      }
      this.setActiveMineral(this.details.minerals[nextIndex]);
    },
    setActiveMineral(mineral) {
      this.activeMineral = mineral;
    },
    getSliderItemStyle(index) {
      const radius = 400; // Adjust radius as needed
      const maxDistance = 85;
      const circumference = 2 * Math.PI * radius;

      // Find the activeIndex from this.details.minerals
      const activeIndex = this.details.minerals.findIndex(mineral => mineral.name.toLowerCase() === this.activeMineral.name.toLowerCase());
      // const totalItems = this.details.minerals.length;

      const numberOfPoints = Math.ceil(circumference / maxDistance);

      const circlePointIndex = activeIndex - index;
      const angleIncrement = (2 * Math.PI) / numberOfPoints;

      const angle = -angleIncrement * circlePointIndex; // clockwise
      const x = radius * Math.sin(angle);
      const y = - radius * Math.cos(angle) + radius;

      // active: x: 0, y: 0
      // -1: x: -a, y: -b

      return {
        transform: `translate(${x}px, ${y}px)`,
      };
    },

    closeModal() {
     this.isDialogVisible = false
     this.updateVisible(false)
    },

    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
};
</script>

<style scoped lang="scss">
.lower {
  background-color: #FFFFFF;
}
.swipe-group {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.modal-card {
  border-radius: 12px;
  background-color: #FFFDE7; /* Light yellow background */
  padding: 16px; /* More padding inside the card */
}

.mineral-value-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6.1px;
  flex-shrink: 0;
  font-size: var(--font-size-smi);
  color: var(--color-white);
}
.mineral-value {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.mineral-padding {
  border-radius: var(--br-81xl);
  border: 3px solid var(--color-lightcyan-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2.5px 2px;
}
.canxi-padding-background-color {
  background-color: var(--color-canxi-secondary);
}
.magie-padding-background-color {
  background-color: var(--color-magie-secondary);
}
.kali-padding-background-color {
  background-color: var(--color-kali-secondary);
}
.selen-padding-background-color {
  background-color: var(--color-selen-secondary);
}
.h2sio4-padding-background-color {
  background-color: var(--color-h2sio4-secondary);
}
.stronium-padding-background-color {
  background-color: var(--color-stronium-secondary);
}
.natri-padding-background-color {
  background-color: var(--color-natri-secondary);
}
.zinc-padding-background-color {
  background-color: var(--color-zinc-secondary);
}
.mineral-box {
  height: 75px;
  width: 75px;
  border-radius: var(--br-81xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 33px 19px;
  box-sizing: border-box;
  z-index: 1;
}
.canxi-box-background-color {
  background-color: var(--color-canxi-primary);
  color: #FFFFFF;
}
.magie-box-background-color {
  background-color: var(--color-magie-primary);
  color: #FFFFFF;
}
.kali-box-background-color {
  background-color: var(--color-kali-primary);
  color: #FFFFFF;
}
.selen-box-background-color {
  background-color: var(--color-selen-primary);
  color: #FFFFFF;
}
.h2sio4-box-background-color {
  background-color: var(--color-h2sio4-primary);
  color: #FFFFFF;
}
.stronium-box-background-color {
  background-color: var(--color-stronium-primary);
  color: #FFFFFF;
}
.natri-box-background-color {
  background-color: var(--color-natri-primary);
  color: #FFFFFF;
}
.zinc-box-background-color {
  background-color: var(--color-zinc-primary);
  color: #FFFFFF;
}
.mineral-detail {
  position: relative;
  line-height: 9px;
  font-weight: 700;
  display: inline-block;
  min-width: 37px;
}
.mineral-benefits {
  align-self: stretch;
  position: relative;
  line-height: 17px;
  font-weight: 500;
  text-align: center;
  z-index: 5;
}
.mineral-concentration-box {
  border-radius: 30px;
  background-color: var(--color-lightcyan-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 9px;
  white-space: nowrap;
  z-index: 5;
}
.mineral-concentration-box-text {
  position: relative;
  line-height: 10px;
  font-weight: 600;
  display: inline-block;
  min-width: 60px;
}
.mineral-recommendation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 6px 0px var(--padding-8xs);
  font-size: var(--font-size-2xs);
  color: rgba(6, 59, 70, 0.8);
}

.v-dialog .v-overlay__content {
  width: 343px;
  margin: 0;
}

.v-dialog .v-overlay__content .v-card {
  padding: 0;
}

.v-dialog {
  .v-card {
    background-color: transparent;
    color: #707070;

    .header {
      background-position: center center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('../../assets/modal-mineral.header.background.svg');
      min-height: 240px;
      color: #FFFFFF;
      padding: 20px 30px;

      >.close {
        position: absolute;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-image: url('../../assets/modal.close.svg');
        top: 12px;
        right: 12px;
        cursor: pointer;
      }

      >.name {
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 22px;
        text-align: center;
        margin-bottom: 5px;
      }

      >.address {
        font-size: var(--font-size-sm);;
        line-height: 19px;
        font-weight: 500;
        padding: 0 30px;
        text-align: center;
      }

      >.statistics {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-areas: 'tds space ph';
        padding-top: 17px;

        >.statistic {
          &{
            text-align: center;

            >.label {
              font-weight: 500;
              font-size: var(--font-size-smi);;
              line-height: 19px;
            }

            >.value {
              font-weight: bold;
              font-size: var(--font-size-9xl);;
              line-height: 30px;
            }

            >.unit {
              font-size: var(--font-size-2xs);
              line-height: 14px;
            }
          }
          &[data-id="tds"]{
            grid-area: tds;
          }
          &[data-id="ph"]{
            grid-area: ph;
          }
        }
      }
    }
    .body{
      background-color: transparent;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      overflow: hidden;
      z-index: 1;

      text-align: center;

      margin-top: -145px;
      position: relative;


      &::before {
        content: '';
        width: 100%;
        top: 107px;
        left: 0;
        position: absolute;
        bottom: 0;
        background-color: #FFF;
        z-index: -1;
      }

      .waterdrop {
        position: relative;
        padding-bottom: 10px;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 85px;
          background-repeat: no-repeat;
          background-position: top center;
          background-image: url('../../assets/modal-mineral.body.waterdrop.background.svg');
        }

        > img {
          margin-right: -16px;
          position: relative;
          z-index: 1;
        }
      }

      > .information {
        background-color: #FFFFFF;
        font-weight: 500;
        font-size: var(--font-size-sm);;
        line-height: 17px;
        padding: 0 40px;
        color: #063B46;
      }

      > .mineral-slider {
        background-color: #FFFFFF;
        position: relative;
        height: 117px;
        overflow: hidden;

        .item {
          & {
            border: 3px solid #FFFFFF;
            width: 80px;
            height: 80px;
            border-radius: 80px;
            text-align: center;
            line-height: 74px;
            vertical-align: middle;
            cursor: pointer;
            transition: transform 0.3s;
            position: absolute;
            top: 5px;
            left: calc(50% - 40px);

            user-select: none;
            font-weight: bold;
            font-size: var(--font-size-smi);

            &.active {
              color: #FFFFFF !important;
              z-index: 1;

              &::after {
                content: '';
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-image: url('../../assets/icon.caret-down.svg');
                background-position: center center;
                position: absolute;
                bottom: -26px;
                left: calc(50% - 10px);
              }
            }
          }

          &[data-slug="canxi"] {
            color: #56A4E3;
            background-color: #E2F9FD;

            &.active {
              border-color: #E2F9FD;
              background-color: #56A4E3;
            }
          }
          &[data-slug="magie"] {
            color: #707FF0;
            background-color: #E8EAFD;

            &.active {
              border-color: #E8EAFD;
              background-color: #707FF0;
            }
          }
          &[data-slug="zinc"] {
            color: #F6CE55;
            background-color: #FBF4DA;

            &.active {
              border-color: #FBF4DA;
              background-color: #F6CE55;
            }
          }
          &[data-slug="kali"] {
            color: #A88AD6;
            background-color: #F1ECF8;

            &.active {
              border-color: #F1ECF8;
              background-color: #A88AD6;
            }
          }
          &[data-slug="natri"] {
            color: #EB8A75;
            background-color: #FCECE9;

            &.active {
              border-color: #FCECE9;
              background-color: #EB8A75;
            }
          }
          &[data-slug="selen"] {
            color: #9BD369;
            background-color: #EFF8E7;

            &.active {
              border-color: #EFF8E7;
              background-color: #9BD369;
            }
          }
          &[data-slug="stronium"] {
            color: #DE8CD9;
            background-color: #F8E7F7;

            &.active {
              border-color: #F8E7F7;
              background-color: #DE8CD9;
            }
          }
          &[data-slug="h2sio4"] {
            color: #54DC89;
            background-color: #E4FBED;

            &.active {
              border-color: #E4FBED;
              background-color: #54DC89;
            }
          }
        }
      }

      > .mineral-info {
        background-color: #FFFFFF;
        > .mineral-benefits {
          font-weight: 500;
          font-size: var(--font-size-smi);
          line-height: 17px;
          color: #063B46;
          padding: 0 40px;
        }

        > .mineral-concentration {
          text-align: center;
          font-size: var(--font-size-sm);
          color: #18426E;
          font-weight: bold;
          padding: 10px 30px;
          line-height: 30px;
          position: relative;

          >span {
            padding: 8px 10px;
            background-color: #D4ECFD;
            border-radius: 30px;
            position: relative;
            z-index: 1;
          }

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 80px;
            right: 80px;
            height: 1px;
            border-top: 1px solid #D4ECFD;

          }
        }
        > .mineral-suggestion {
          font-size: var(--font-size-xs);
          padding-bottom: 20px;

          >img {
            vertical-align: middle;
            margin-right: 5px;
          }
        }
      }
    }
    /* Media Query for mobile devices */
    @media (max-width: 768px) {
      .body {
        overflow: scroll; /* Enable scrolling for mobile devices */
      }
    }
    .footer {
      &{
        padding: 10px;
        text-align: center;
        font-weight: 500;
        font-size: var(--font-size-2xs);
        position: relative;
        background-color: #FFFFFF;
        border-radius: 12px;
        display: flex;
        justify-content: space-evenly; /* Ensures equal spacing between and around items */
        align-items: center; /* Vertically center items within the footer */
        width: 100%; /* Ensure the footer takes full width if not already set */

        img {
          vertical-align: middle;
          width: auto; /* Maintain the aspect ratio of the images */
          height: 50px; /* Adjust height as necessary */

          &+img {
            margin-left: 8px;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 32px;
        right: 32px;
        height: 1px;
        border-top: 1px dotted #E0E0E0;
      }
    }
  }
}

</style>

