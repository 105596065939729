<template>
    <div>
      <MobileHeader title="FAQs" />
      
      <div v-if="loading">
        Loading...
      </div>
      <div v-else>
        <div v-html="pageContent"></div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import MobileHeader from '../components/MobileHeader.vue';
  
  export default {
    name: 'FAQPage',
    components: {
      MobileHeader
    },
    data() {
        return {
          pageContent: '',
          loading: true
        };
      },
      mounted() {
        this.fetchPageContent();
      },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      fetchPageContent() {
          axios.get('http://localhost:8000/api/flatpages/faq/')  // Adjust URL as needed for your Django setup
            .then(response => {
              this.pageContent = response.data.content;  // Adjust according to the response structure
              this.loading = false;
            })
            .catch(error => {
              console.error('Error fetching page content:', error);
              this.loading = false;
            });
        }
    }
  }
  </script>
  
  <style scoped>
  .navbar {
    box-shadow: 0 2px 4px rgba(0,0,0,.1); /* Optional: adds shadow for depth */
  }
  </style>
    